import React, { Component } from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import iconArrowRight from '../../images/icon-arrow-right.svg';

export default class SubscribeForm extends Component {
  state = {
    email: '',
    fName: '',
    lName: '',
  };

  _handleChange = (e) => {
    this.setState({ [`${e.target.name}`]: e.target.value });
  };

  _handleSubmit = (e) => {
    e.preventDefault();
    addToMailchimp(this.state.email, {
      FNAME: this.state.fName,
      LNAME: this.state.lName,
    })
      .then((data) => {
        if (this.props.callback) {
          this.props.callback(data);
        }
      })
      .catch(() => {});
  };

  render() {
    return this.props.page === 'home' ? (
      <form className="wrapper wrapper-xs index-form d-flex" onSubmit={this._handleSubmit}>
        <div className="form-element email">
          <input
            type="text"
            placeholder="Enter your email"
            name="email"
            value={this.state.email}
            onChange={this._handleChange}
          />
        </div>
        <div className="form-element send" onClick={this._handleSubmit}>
          <a href="#" className="btn-search">
            <img src={iconArrowRight} alt="Arrow Right" />
          </a>
        </div>
      </form>
    ) : (
      <form className="form-subscribe d-flex" onSubmit={this._handleSubmit}>
        <div className="form-element first-name">
          <label>
            First name<span>*</span>
          </label>
          <input type="text" name="first-name" name="fName" value={this.state.fName} onChange={this._handleChange} />
        </div>
        <div className="form-element last-name">
          <label>
            Last name<span>*</span>
          </label>
          <input type="text" name="last-name" name="lName" value={this.state.lName} onChange={this._handleChange} />
        </div>
        <div className="form-element email">
          <label>
            Email address<span>*</span>
          </label>
          <input type="text" name="email" name="email" value={this.state.email} onChange={this._handleChange} />
        </div>
        <div className="form-element submit">
          <input type="submit" value="Join Today" className="btn btn-md btn-primary-ii btn-submit" />
        </div>
      </form>
    );
  }
}
